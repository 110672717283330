export default [
  { key: "Gifts" },
  { key: "Notification" },
  { key: "Achievement" },
  { key: "Profile" },
  { key: "Ranking" },
  { key: "Rules" },
  { key: "Ambassador" },
  { key: "Authenticate" },
  { key: "News" },
  { key: "Home" }
]
