import EventCalendar from "../Store/EventCalendar/reducer"
import RulesHeader from "../Store/RulesHeader/reducer"
import RulesCenter from "../Store/RulesCenter/reducer"
import RulesFooter from "../Store/RulesFooter/reducer"
export default {
  EventCalendar,
  RulesHeader,
  RulesCenter,
  RulesFooter
}
